
import AddToCart from '~/components/product/AddToCart.vue'
export default {
  name: 'ComparatorSlice',
  props: ['slice'],
  components: { AddToCart },
  data(){
    return {
      switch: false,
      colsOrder: [1,2,3,4],
      touchstartX: 0,
      touchendX: 0,
      hasProduct3: this.slice.primary.product_3 && this.slice.primary.product_3.length,
    }
  },
  methods: {
    // switchCols(){
    //   this.switch = !this.switch
    //   this.colsOrder = this.switch ? [1,2,3,4] : [1,2,4,3]
    // }
  },
  mounted() {
    // setTimeout(()=>{
    //   const slider = document.querySelector(`#comparator`)
    //   slider.addEventListener('touchstart', e => { this.touchstartX = e.changedTouches[0].screenX }, { passive: true })
    //   slider.addEventListener('touchend', e => {
    //     this.touchendX = e.changedTouches[0].screenX
    //     this.switchCols()
    //   }, { passive: true })
    // }, 1000)
  }
}
